/*--Dock--*/
footer {
  bottom: 0;
  display: inline-block;
  height: 4.4em;
  left: 50%;
  min-width: 35em;
  padding: 0 10px;
  perspective: 1000px;
  position: absolute;
  text-align: center;
  transform: translatex(-50%);
}

footer::before {
  background: linear-gradient(
    to top,
    rgba(230, 230, 230, 1) 0%,
    rgba(163, 163, 163, 1) 100%
  );
  bottom: 0;
  content: "";
  height: 4em;
  left: 0;
  margin-top: 15px;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotatex(45deg);
  width: 100%;
  z-index: -1;
}
footer ul {
  display: inline-block;
  margin: 0 0.1em;
  padding: 0;
  transform-origin: bottom center !important;
}
footer li {
  display: inline-block;
  vertical-align: top;
  position: relative;
  transform: scale(1);
  transform-origin: bottom center;
  transition: transform 2s;
}

.jump {
  -webkit-animation: JUMP 600ms 2 cubic-bezier(0.4, 0.01, 0.6, 0.99);
}
footer ul li img {
  -webkit-box-reflect: below -5px -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0%, rgba(230, 230, 230, 0.2)),
      color-stop(100%, rgba(255, 255, 255, 0))
    );
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin: 0 5px;
  position: relative;
  transform: scale(1);
  /* transform-origin: bottom center!important; */
  -webkit-transition: all 0.3s;
  -webkit-transform-origin: 50% 100%;
  transition: all 0.2s;
  width: 4em;
}

footer ul li:hover img {
  margin: 0 10px;
  /* transform: scale(1.2); */
  -webkit-transform: scale(2);
  margin: 0 2em;
}

footer ul li:hover + li img,
footer ul li.prev img {
  -webkit-transform: scale(1.5);
  margin: 0 1.5em;
}

@-webkit-keyframes JUMP {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 20px;
    padding-bottom: 20px;
  }
  100% {
    bottom: 0;
  }
}
