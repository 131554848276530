body {
  background: url(./assets/images/home-screen.jpg) center center / cover fixed
    no-repeat;
  font-family: "Helvetica", sans-serif;
  margin: 0;
  overflow: hidden;
  padding: 0;
  perspective-origin: 0 0;
}

/*--Header--*/
header {
  background: linear-gradient(
    to bottom,
    rgba(230, 230, 230, 1) 0%,
    rgba(163, 163, 163, 1) 100%
  );
  display: block;
  font-size: 0.9em;
  height: 1.3em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
header ul {
  margin: 0 5px;
  padding: 0 3px;
}
header li {
  display: inline-block;
  line-height: 1.3em;
  padding: 0 0.5em;
  position: relative;
  cursor: default;
}
header .left {
  float: left;
}
header .right {
  float: right;
}
a {
  color: #000;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.apple i {
}
header .selected {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(89, 120, 245, 1)),
    color-stop(100%, rgba(51, 94, 246, 1))
  );
  color: #fff;
  border-bottom: 0.1em solid #5978f5;
}
/*--Menus--*/
.visible {
  display: block !important;
}
.shortcut:after {
  content: attr(shortcut);
  float: right;
  padding-right: 20px;
}
.search {
  padding: 5px 10px !important;
}
.noChoice {
  color: #adadad;
  &:hover {
    background: #fff !important;
    color: #adadad !important;
  }
}
#volume-icon-li {
  width: 1em;
}
.submenu {
  display: none;
  & input[type="text"] {
    border-radius: 2em;
    border: 1px solid #333;
    height: 2em;
    padding: 0 10px;
  }
  & input[type="range"] {
    transform: rotate(-90deg);
    margin-left: -50px;
    margin-top: 65px;
  }
  & ul {
    background: #fff;
    border-radius: 0 0 5px 5px;
    color: #333;
    font-size: 14px;
    left: 0px;
    margin: 0 !important;
    overflow: hidden;
    padding: 0 5px 0 0;
    position: absolute;
    top: 1.3em;
    width: auto;
    white-space: nowrap;
    &.volume {
      height: 10em;
      width: 2em;
      margin: 0;
      padding: 0;
      position: absolute;
      overflow: visible;
      & li {
        margin: 0;
        padding: 0;
      }
    }
    & li {
      display: block !important;
      vertical-align: middle;
      float: none;
      margin-right: 75px;
      font-weight: 400;
      padding: 2.5px 0px 2.5px 15px;
      width: 100%;
      &.spacer {
        background: rgba(211, 211, 211, 0.8);
        height: 1px !important;
        margin: 2px 0;
        padding: 0;
        width: 102%;
        position: relative;
        &:hover {
          background: rgba(211, 211, 211, 0.8);
        }
      }
    }
  }
  & li:hover:not(.volume) {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, rgba(89, 120, 245, 1)),
      color-stop(100%, rgba(51, 94, 246, 1))
    );
    color: #fff;
  }
  & .hasSubMenu:after {
    content: "\f04b";
    font-family: FontAwesome;
    font-size: 0.8em;
    display: inline-block;
    position: absolute;
    right: 25px;
  }
}

/*--Desktop--*/
#Desktop {
  /*-moz-column-count: 15;
	-moz-column-gap: 10px;
	-webkit-column-count: 15;
	-webkit-column-gap: 10px;
	-webkit-column-width: 80px;
	column-count: 15;
	column-gap: 10px;
	column-width: 80px;
	column-fill: balance;
-moz-column-fill: balance;
-webkit-column-fill: balance;
	direction: rtl;*/
  display: flex;
  align-content: right;
  flex-direction: column;
  align-items: flex-end;
  height: 85vh;
  padding: 25px;
  position: absolute;
  right: 0 !important;
  text-align: right;
  z-index: -1;
  width: 100%;
}
#Desktop span.icon {
  display: block;
  float: right;
  margin: 5px 0;
  transition: all 0.2s;
  width: auto;
  z-index: 1;
  text-align: center;
  &:after {
    color: #fff;
    content: attr(data-title);
    display: block;
    margin-bottom: 10px;
    padding: 1px 5px;
    text-shadow: 0px 0px 4px #000;
    transition: all 0.2s;
  }
}
#Desktop span.icon img {
  border: 2px solid rgba(225, 225, 225, 0);
  border-radius: 6px;
  transition: all 0.2s;
  width: 75px;
}
.focus img {
  border: 2px solid rgba(225, 225, 225, 0.5) !important;
  border-radius: 6px;
}
span.focus:after {
  background: #146ece;
  border-radius: 15px;
  text-shadow: none !important;
}
/*--Dock--*/
footer {
  bottom: 0;
  display: inline-block;
  height: 4.4em;
  left: 50%;
  min-width: 35em;
  padding: 0 10px;
  perspective: 1000px;
  position: absolute;
  text-align: center;
  transform: translatex(-50%);
  &:before {
    background: linear-gradient(
      to top,
      rgba(230, 230, 230, 1) 0%,
      rgba(163, 163, 163, 1) 100%
    );
    bottom: 0;
    content: "";
    height: 4em;
    left: 0;
    margin-top: 15px;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotatex(45deg);
    width: 100%;
    z-index: -1;
  }
}
footer ul {
  display: inline-block;
  margin: 0 0.1em;
  padding: 0;
  transform-origin: bottom center !important;
}
footer li {
  display: inline-block;
  vertical-align: top;
  position: relative;
  transform: scale(1);
  transform-origin: bottom center;
  transition: transform 2s;
  &:before {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    color: #fff;
    content: attr(data-title);
    display: block;
    float: left;
    font-size: 0.9em;
    left: 50%;
    margin: 2px auto;
    opacity: 0;
    padding: 0 10px;
    position: absolute;
    text-align: center;
    top: -35px;
    transform: translatex(-50%);
    transform-origin: bottom center !important;
    visibility: hidden;
    width: auto;
  }
  &:hover:before {
    opacity: 1;
    top: -45px;
    visibility: visible;
  }
}
.jump {
  -webkit-animation: JUMP 600ms 2 cubic-bezier(0.4, 0.01, 0.6, 0.99);
}
footer img {
  -webkit-box-reflect: below -5px -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0%, rgba(230, 230, 230, 0.2)),
      color-stop(100%, rgba(255, 255, 255, 0))
    );
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  margin: 0 5px;
  position: relative;
  transform: scale(1);
  transform-origin: bottom center !important;
  transition: all 0.2s;
  width: 4em;
  &:hover {
    margin: 0 10px;
    transform: scale(1.2);
  }
}

@-webkit-keyframes JUMP {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 20px;
    padding-bottom: 20px;
  }
  100% {
    bottom: 0;
  }
}
