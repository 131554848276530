/*--Header--*/
header {
  background: linear-gradient(
    to bottom,
    rgba(230, 230, 230, 1) 0%,
    rgba(163, 163, 163, 1) 100%
  );
  display: block;
  font-size: 0.9em;
  height: 1.3em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
header ul {
  margin: 0 5px;
  padding: 0 3px;
}
header li {
  display: inline-block;
  line-height: 1.3em;
  padding: 0 0.5em;
  position: relative;
  cursor: default;
}
header .left {
  float: left;
}
header .right {
  float: right;
}
a {
  color: #000;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

header .selected {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(89, 120, 245, 1)),
    color-stop(100%, rgba(51, 94, 246, 1))
  );
  color: #fff;
  border-bottom: 0.1em solid #5978f5;
}
